import React, { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import "./footer.css";

export default function Footer() {

  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_i46xji7',
  //    'template_gioyje5',
  //     form.current,
  //      'hJ66vEf2Vd3X4G_2_')

  //     .then(
  //       () => {
  //         toast.success('Subscribed successfully!',
  //         {
  //           position:"top-center"
  //         })
  //         window.location.reload(false)
  //       },
  //       () => {
  //         toast.error('Failed to subscribe, please try again')
  //       }
  //     );
  // };

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm(
        "service_3leo166", // EmailJS service ID (production)
        // "service_jxf4rul", // EmailJS service ID (test)
        "template_rmi06f5", // EmailJS newsletter subscritpion template ID (production)
        // "template_z9qtas2", // EmailJS newsletter subscritpion template ID (test)
        e.target,
        "hJ66vEf2Vd3X4G_2_" // EmailJS account public API key (production)
        // "R9mvnJQaxs8MIYsDS" // EmailJS account public API key (test)
      )
      .then(
        (result) => {
          toast.success('Subscribed successfully!', {
            position:"top-center",
          });
          window.location.reload(false);
        },
        (error) => {
          toast.error('Failed to subscribe, please try again', {
            position: "bottom-right",
          });
          console.error(error);
        }
      );
  };


  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <a href="#header" class="scrollto footer-logo">
                  <img src="assets/img/hero-logo.png" alt="" />
                </a>
                <h3>IQ Tech</h3>
                <p>
                Subscribe below to get future updates.
                </p>
              </div>
            </div>

            <div class="row footer-newsletter justify-content-center">
              <div class="col-lg-6">
                <form  onSubmit={sendEmail}>
                <input
  type="email"
  class="form-control"
  name="from_email"
  id="from_email"
  placeholder="Your Email"
  required
/>
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
            <div class="social-links justify-content-center">
            <a href="https://www.linkedin.com/company/iqtech-us/" target="_blank">
                <i class="bx bxl-linkedin"></i>
              </a>
              {/* <a href="#">
                <i class="bx bxl-facebook"></i>
              </a>
              <a href="#">
                <i class="bx bxl-instagram"></i>
              </a> */}
              <a href="https://api.whatsapp.com/send?phone=923004055699" target="_blank">
                <i class="bx bxl-whatsapp"></i>
              </a>
             
            </div>
           
          </div>
          <h4 className="copyright" >
              Copyright © 2023 IQTech. All rights reserved 
            </h4>
        </div>
      </footer>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
    </>
  );
}
