import React, { useRef, useState } from "react";
import "./Projects.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/pagination";
import TsrImg from "../../img/Projects/TSR.png";
import ABwebImg from "../../img/Projects/ABweb.png";
import IqTimg from "../../img/Projects/Iq_trivia.png";

import AOS from "aos";
import "aos/dist/aos.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";

function Projects() {
  return (
    <div id="project">
      <div className="project-title">
        <h1 data-aos="fade-right">Our Projects</h1>
        <p id="project-text" data-aos="fade-right">
          We prioritize in creating engaging design and a seamless user experience to drive optimum results for your business.
        </p>
      </div>

      <div className="project-container">
        <Swiper
       
          spaceBetween={80}
          loop={true}
          centeredSlides={true}
          
          autoplay={{
            autoplay: 5000,
            delay: 3500,
            waitForTransition: 2600,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Navigation, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="project-row" id="even-row">
              <div className="project-text">
                <h2>Mobile Applications</h2>
                <h1>TSR Mobile Game</h1>
                {/*     <p>
                  {" "}
                  TSR: The Silk Road is a highly interactive and educational
                  game that is developed using Unity and features the real
                  architecture of the medieval era at the beginning of the Silk
                  Road. It is crafted by skilled developers and 3D modelers to
                  provide an immersive and entertaining experience that is
                  rooted in the rich history of the Silk Road. Players will have
                  the opportunity to explore and learn about this important
                  historical period through engaging gameplay and interactive
                  elements.
                </p> */}
              </div>

              <img src={TsrImg} className="project-img" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="project-row">
              <div className="project-text">
                <h2>Web Applications</h2>
                <h1>Artist Portfolio & Studio Websites</h1>

                {/*  <p>
                  IQTriva is a highly engaging and educational mobile quiz
                  application developed by IQTECH. It is designed to not only
                  challenge users' knowledge, but also to provide an immersive
                  and interactive learning experience through the use of stories
                  and other scoring tactics. The app has been crafted by a team
                  of skilled Flutter developers and designers to ensure that it
                  is visually appealing and user-friendly. Whether you are
                  looking to test your knowledge or simply want to learn more,
                  IQTriva is a must-have for anyone looking for a fun and
                  engaging quiz experience on their mobile device.
                </p> */}
              </div>

              <img src={ABwebImg} className="project-img" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="project-row" id="even-row">
              <div className="project-text">
                <h2>Mobile Applications</h2>
                <h1>IQ Trivia Mobile App</h1>
                {/* <p>
                  {" "}
                  IQTriva is a highly engaging and educational mobile quiz
                  application developed by IQTECH. It is designed to not only
                  challenge users' knowledge, but also to provide an immersive
                  and interactive learning experience through the use of stories
                  and other scoring tactics. The app has been crafted by a team
                  of skilled Flutter developers and designers to ensure that it
                  is visually appealing and user-friendly. Whether you are
                  looking to test your knowledge or simply want to learn more,
                  IQTriva is a must-have for anyone looking for a fun and
                  engaging quiz experience on their mobile device.
                </p> */}
              </div>

              <img src={IqTimg} className="project-img" />
            </div>
          </SwiperSlide>

          
        </Swiper>
      </div>
    </div>
  );
}

export default Projects;
