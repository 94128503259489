import React, { useRef } from "react";
import "./Contact.css";
// import c1 from "../../img/clients/client-1.png";
// import c2 from "../../img/clients/client-2.png";
// import c3 from "../../img/clients/client-3.png";
// import c4 from "../../img/clients/client-4.png";
// import c5 from "../../img/clients/client-5.png";
// import c6 from "../../img/clients/client-6.png";
// import c7 from "../../img/clients/client-7.png";
// import c8 from "../../img/clients/client-8.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import Client from "../Clients/Client";
export default function ContactPage() {

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm(
        "service_3leo166", // EmailJS service ID (production)
        // "service_jxf4rul", // EmailJS service ID (test)
        "template_jui6jjb", // EmailJS Contact US Form template ID (production)
        // "template_eiqhb3d", // EmailJS Contact US Form template ID (test)
        e.target,
        "hJ66vEf2Vd3X4G_2_" // EmailJS account public API key (production)
        // "R9mvnJQaxs8MIYsDS" // EmailJS account public API key (test)
      )
      .then(
        (result) => {
          toast.success("Message successfully sent!", {
            position: "bottom-right",
          });
          window.location.reload(false);
        },
        (error) => {
          toast.error("Failed to send the message, please try again", {
            position: "bottom-right",
          });
          console.error(error);
        }
      );
  };




  return (
    <>
      <div>
        <section id="contact" class="contact section-bg">
          {/* <Client /> */}
          <div class="container">
            <div class="section-title">
              <h2 data-aos="fade-up">
                Get in touch with our team and let's bring your vision to life!
              </h2>
              <h3 className="small-heading-white" data-aos="fade-up">
                Contact us today to receive a free consultation!
              </h3>
              {/* <h4 className="heading-explain" data-aos="fade-up">
                Explain your idea and get a free quote!
                <br />
              </h4> */}
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div
                  class="info d-flex flex-column justify-content-center"
                  data-aos="fade-right"
                >
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Islamabad, Pakistan</p>
                  </div>

                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <a href="mailto: iqtech.cs@gmail.com">
                      iqtech.cs@gmail.com
                    </a>
                  </div>

                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <a href="tel: +92 300 4055699"> +92 300 4055699</a>
                  </div>
                </div>
              </div>


 {/* Contact Us Form Email.js Start */}


              <div class="col-lg-8 mt-5 mt-lg-0">
                <form
                  
                  onSubmit={sendEmail}
                  class="php-email-form"
                  data-aos="fade-left"
                >
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="from_name"
                        class="form-control"
                        id="from_name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input
  type="email"
  class="form-control"
  name="from_email"
  id="from_email"
  placeholder="Your Email"
  required
/>
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      name="message"
                      id="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <input type="submit" className="button" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
 {/* Contact Us Form End  */}


      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

