import React, { Component } from "react";
import "./about.css";
import aboutimg from "../../img/about.jpg";

export default class About extends Component {
  render() {
    return (
      <div>
        <section id="about" class="about">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>About Us</h2>
              <p>
                IQTech is a Technology Solutions and Product Development company.{" "}
              </p>
            </div>
            <div class="row">
              <div class="col-lg-6" data-aos="fade-right">
                <div class="">
                  <img src={aboutimg} class="img-fluid image " alt="" />
                </div>
              </div>
              <div class="col-lg-6" data-aos="fade-left">
                <div class="content pt-4 pt-lg-0 pl-0 pl-lg-3 ">
                  <h3>Delivering Digital Excellence</h3>

                  <ul>
                    <li>
                      <i class="bx bx-check-double"></i>At IQTech, we are
                      dedicated to delivering innovative solutions that meet the
                      unique needs of our clients.
                    </li>
                    <li>
                      <i class="bx bx-check-double"></i>Our team of experienced
                      professionals have a deep understanding of the latest
                      technologies and a track record of successfully delivering
                      complex projects and systems.
                    </li>

                    <li>
                      <i class="bx bx-check-double"></i>Whether you are looking
                      for a custom website, a mobile app, or a game, we have the
                      skills and expertise to bring your vision to life.
                    </li>
                    <li>
                      <i class="bx bx-check-double"></i> We use agile
                      methodology to ensure that all of our projects are
                      delivered efficiently and effectively, and we have a team
                      of experienced professionals who are experts in their
                      field.
                    </li>
                  </ul>
                  <p>
                    If you are looking for a software company that can deliver
                    innovative solutions for your business, look no further than
                    IQTech. Our team is ready to help you take your business to
                    the next level through the power of technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
