import React, { Component } from "react";
import heroimg from "../../img/iqimg1.png";
import Typewriter from "typewriter-effect";

import "./banner.css";
import AOS from "aos";
import "aos/dist/aos.css";

class Banner extends Component {
  render() {
    AOS.init();
    return (
      <div>
        <div>
          <section id="hero">
            <div class="hero-container">
              <a href="index.html" className="hero-logo" data-aos="zoom-in">
                {/* <img src={hlogo} alt="" /> */}
              </a>

              <h1 data-aos="fade-right">
                {/* <Typewriter
                options={{
                  strings: ['One-Stop Solution For All Your Business Needs'],
                  autoStart: true,
                  loop: true,
                  delay:120,
                  deleteSpeed:1000000
                }}
              /> */}
                Innovative Solutions For A Digital World
              </h1>
              <h2 data-aos="fade-up">
                Creating{" "}
                <span className="software">
                  <Typewriter
                    options={{
                      strings: ["Websites", "Apps", "Games"],
                      autoStart: true,
                      loop: true,
                      pauseFor: 500,
                      delay: 200,
                      deleteSpeed: 100,
                    }}
                  />
                </span>{" "}
                & Digital Experience
              </h2>
              <a
                data-aos="fade-up"
                href="#contact"
                class="btn-get-started scrollto"
              >
                Get Started
              </a>
            </div>
            <div className="hero-img" data-aos="fade-left">
              <img src={heroimg} />
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Banner;
