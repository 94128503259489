import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./Techstack.css";
import styles from "./Techstack.css";
import blender from "../../img/techlogos/blender.png";
import flutter from "../../img/techlogos/flutter.svg";
import react from "../../img/techlogos/atom.png";
import photoshop from "../../img/techlogos/photoshop.png";
import illustrator from "../../img/techlogos/illustrator.png";
import xd from "../../img/techlogos/xd.png";
import figma from "../../img/techlogos/figma.png";
import unreal from "../../img/unreal.png";
import unity from "../../img/unity.png";
import firebase from "../../img/techlogos/FirebaseLogo.png";
import dart from "../../img/techlogos/DartLogo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Products from "../Products/Products";


function Techstack() {
  const { ref: myRef, inView: myElementIsVisible } = useInView();
  const { ref: myRef2, inView: myElementIsVisible1 } = useInView();
  const { ref: myRef3, inView: myElementIsVisible2 } = useInView();
  const { ref: myRef4, inView: myElementIsVisible3 } = useInView();


  return (
    <>
      <div className="tech-class" id="tech">
        <div className="section-title">
          <h2 data-aos="fade-up">Our Tech Stack </h2>
          <p id="tech-text" data-aos="fade-up">
            We are dedicated to building products using latest
            technology stacks.
          </p>
        </div>

        <div className="tech-stack-class-container">
          <div className="tech-stacks">
            <h2
              ref={myRef}
              className={` hidden ${myElementIsVisible ? "show" : ""} `}
            >
              Design
            </h2>
            <div ref={myRef} className="tech-block-horizontal block1">
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible ? "show" : ""} `}
              >
                <img src={figma} className="img-tool img" />
                <h3 className="smaller-heading h3-services hide">Figma</h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible ? "show" : ""} `}
              >
                <img
                  src={xd}
                  loading="lazy"
                  width="Auto"
                  alt=""
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">ADOBE XD</h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible ? "show" : ""} `}
              >
                <img
                  src={illustrator}
                  loading="lazy"
                  alt=""
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">
                  IllUSTRATOR
                </h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible ? "show" : ""} `}
              >
                <img
                  src={photoshop}
                  loading="lazy"
                  alt=""
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">Photoshop</h3>
              </div>
            </div>
          </div>

          <div className="tech-stacks">
            <h2>Web</h2>
            <div className="tech-block-horizontal">
              <div
                ref={myRef2}
                id="toolimage"
                className={` hidden ${myElementIsVisible1 ? "show" : ""} `}
              >
                <img src={react} className="img-tool img" />
                <h3 className="smaller-heading h3-services hide">React</h3>
              </div>
              <div
                ref={myRef2}
                id="toolimage"
                className={` hidden ${myElementIsVisible1 ? "show" : ""} `}
              >
                <img
                  src="https://uploads-ssl.webflow.com/602e7c287eefab52cb3f36dd/604ba60861b33baa34b92798_Angular.svg"
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - Angular"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Angular</h3>
              </div>              
              <div
                ref={myRef2}
                id="toolimage"
                className={` hidden ${myElementIsVisible1 ? "show" : ""} `}
              >
                <img
                  src="https://uploads-ssl.webflow.com/602e7c287eefab52cb3f36dd/604ba607356c52d7e458ccd2_Vue.Js.svg"
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - Vue.Js"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Vue</h3>
              </div>


              <div
                ref={myRef2}
                id="toolimage"
                className={` hidden ${myElementIsVisible1 ? "show" : ""} `}
              >
                <img
                  src="https://uploads-ssl.webflow.com/602e7c287eefab52cb3f36dd/604ba607998412271f1e0b81_Node.svg"
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - Node.Js"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Node js</h3>
              </div>
            </div>
          </div>

          <div className="tech-stacks">
            <h2>Mobile</h2>
            <div ref={myRef3} className="tech-block-horizontal block3">
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible2 ? "show" : ""} `}
              >
                <img
                  src={react}
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - React"
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">
                  React Native
                </h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible2 ? "show" : ""} `}
              >
                <img
                  src={flutter}
                  loading="lazy"
                  alt=""
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Flutter</h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible2 ? "show" : ""} `}
              >
                <img
                  src={dart}
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - React"
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">
                  Dart
                </h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible2 ? "show" : ""} `}
              >
                <img
                  src={firebase}
                  loading="lazy"
                  alt="Imaginary Cloud Tech Stack - React"
                  className="img-tool"
                />
                <h3 className="smaller-heading h3-services hide">
                  FireBase
                </h3>
              </div>
            </div>
          </div>

          <div className="tech-stacks">
            <h2>Game</h2>
            <div ref={myRef4} className="tech-block-horizontal">
              <div
                ref={myRef4}
                id="toolimage"
                className={` hidden ${myElementIsVisible3 ? "show" : ""} `}
              >
                <img
                  src={unity}
                  loading="lazy"
                  alt="Unity"
                  id="specialimage"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Unity</h3>
              </div>

              <div
                ref={myRef4}
                id="toolimage"
                className={` hidden ${myElementIsVisible3 ? "show" : ""} `}
              >
                <img
                  src={unreal}
                  loading="lazy"
                  alt="Ureal Engine"
                  id="specialimage"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Unreal</h3>
              </div>
              <div
                id="toolimage"
                className={` hidden ${myElementIsVisible3 ? "show" : ""} `}
              >
                <img
                  src={blender}
                  loading="lazy"
                  alt="Blender"
                  id="specialimage"
                  className="img-tool img"
                />
                <h3 className="smaller-heading h3-services hide">Blender</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Techstack;
