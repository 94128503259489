import React, { Component } from "react";
import "./WhyUs.css";
import relaibility from "../../img/relaeability.svg";
import quality from "../../img/quality.svg";
import unique from "../../img/unique.svg";

export default class Services extends Component {
  render() {
    return (
      <div>
        <section id="whyus" class="services">
          <div class="container">
            <div class="section-title">
              <h2 data-aos="fade-up">What Sets Us Apart</h2>
              <p id="sub-heading" data-aos="fade-up">
                At <strong> IQTech </strong> we are dedicated to delivering
                innovative solutions that meet the unique needs of our clients.
                Our team of experienced professionals are constantly learning
                and adapting to the latest technologies, ensuring that we are
                always at the forefront of the industry.
              </p>
            </div>

            <div className="whatsetus-topic-container">
              <div
                className="whatsetus-topic-wrapper"
                id="first-div"
                data-aos="fade-up"
              >
                {/* <i class="bx bx-receipt"></i> */}
                <div class="whatsetus-topic-header">
                  <img src={relaibility} class="whatsetus-topic-icon" />
                  <div className="whatsetus-topic-title">
                    Agile Project Delivery
                  </div>
                </div>
                <div class="whatsetus-topic-item-wrapper">
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Iterative & Incremental
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">

                      Flexibile and Collaborative

                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">

                      DevOps, CI/CD best practices

                    </div>
                  </div>
                </div>
                <div class="whatsetus-topic-blue-circle"></div>
              </div>

              <div
                className="whatsetus-topic-wrapper"
                id="sec-div"
                data-aos="fade-up"
              >
                {/* <i class="bx bx-receipt"></i> */}
                <div class="whatsetus-topic-header">
                  <img src={quality} class="whatsetus-topic-icon" />
                  <div className="whatsetus-topic-title">
                    Progressive Technologies
                  </div>
                </div>
                <div class="whatsetus-topic-item-wrapper">
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Responsive Design
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Reliable and Scalable{" "}
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Fully Customizable
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Fully Customizable
                    </div>
                  </div>
                </div>
                <div class="whatsetus-topic-blue-circle"></div>
              </div>

              <div
                className="whatsetus-topic-wrapper"
                id="third-div"
                data-aos="fade-up"
              >
                {/* <i class="bx bx-receipt"></i> */}
                <div class="whatsetus-topic-header">
                  <img src={unique} class="whatsetus-topic-icon" />
                  <div className="whatsetus-topic-title">
                    Fun Work Environment
                    <br />
                  </div>
                </div>
                <div class="whatsetus-topic-item-wrapper">
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Flexible Working Hours
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Excellent Teamwork
                    </div>
                  </div>
                  <div className="whatsetus-topic-item">
                    <div className="whatsetus-topic-bulletpoint"></div>
                    <div className="whatsetus-topic-text">
                      Training and Development
                    </div>
                  </div>
                </div>
                <div class="whatsetus-topic-blue-circle"></div>
              </div>
            </div>
            {/* <div
                class="Simage col-lg-6 order-1 order-lg-2"
                data-aos="fade-left"
                data-aos-delay="100"
              ></div> */}
          </div>
        </section>
      </div>
    );
  }
}
