import React, { useEffect } from "react";
import "./Services.css";
import web1 from "../../img/Capture.PNG";
import mobilen from "../../img/Group5.png";
import gd from "../../img/Group6.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Services() {
  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <>
      <div className="our-services" id="service">
        <h1 data-aos="fade-up">Our Services</h1>
        <h2 data-aos="fade-up">
          We specialize in Web Development, Mobile Apps, Game Development and
          UI/UX design. We also provide Staffing services to supplement your
          teams.
        </h2>
        <div className="services-container">
          <div className="our-service-cards" data-aos="zoom-in">
            <img src={web1} />
            <h3>Web Development</h3>
            <p>
              We build custom websites that are fast, scalable, and responsive.
              Our team is skilled in using a variety of technologies, including
              Java, HTML, CSS, JavaScript, and frameworks such as React and Angular,
              to build dynamic, interactive websites that deliver an excellent
              user experience.
            </p>
          </div>

          <div className="our-service-cards" data-aos="zoom-in">
            <img src={mobilen} />
            <h3>App Development</h3>
            <p>
              We build high-performance, cross-platform mobile apps for iOS and
              Android. Our team is skilled in using a variety of technologies,
              including native development and frameworks such as Flutter and
              React Native, to build visually stunning, intuitive apps that
              deliver an excellent user experience.
            </p>
          </div>

          <div className="our-service-cards" data-aos="zoom-in">
            <img src={gd} />
            <h3>Game Development</h3>
            <p>
              We build immersive, interactive games for a variety of platforms,
              including mobile, desktop, and VR/AR. Our team is skilled in using
              a variety of game engines, including Unity and Unreal Engine, to
              build games that are engaging and entertaining for the users.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
