import "./App.css";
import About from "./Components/About/About";
import Banner from "./Components/Banner/Banner";
import Card from "./Components/Cards/Card";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Projects from "./Components/Projects/Projects";
//import Services from "./Components/Services/Services";

import Client from "./Components/Clients/Client";
import ContactPage from "./Components/ContactUs/ContactPage";
 import FAQ from "./Components/FAQ/FAQ"; 
 import Feature from "./Components/Features/Feature"; 
 import Whyus from "./Components/WhyUs/Whyus"; 
 import Modal from "./Components/Modal/Modal"; 
import Techstack from "./Components/Techstack/Techstack";
import Services from "./Components/Services/Services";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Banner />
      <About />
      <Services />
       { <Whyus /> }
      { <Projects /> }
      <Techstack />
      <ContactPage />
      <Footer />
    </div>
  );
}

export default App;
