   import React, { FaBars,FaTimes} from "react-icons/fa";
   import{  useRef } from "react";
   import iqlogo from "../../img/iqtechlogo.png";
   //import iqlogo from "../../img/logonew.jpg";
  
   import "./Navbar.css";


   
     function Navbar() {
       const navRef = useRef();
       const showNavbar = () =>{
         navRef.current.classList.toggle("responsive_nav");
       }
      
       return (
         <div className=" header-nav" >
           <div id="logo-div" ><img id="logo" src={iqlogo}/><h3>IQTech</h3> </div>
           <nav ref={navRef} >
             <a href="/#" onClick={showNavbar}> Home </a>
             <a href="/#about" onClick={showNavbar}> About Us </a>
             <a href="/#service" onClick={showNavbar}>Services </a>
             <a href="/#whyus" onClick={showNavbar}> Why Us </a>
             <a href="/#project" onClick={showNavbar}>Projects </a>
             <a href="/#tech" onClick={showNavbar}>Tech Stack </a>
             <a href="/#contact" onClick={showNavbar}> Contact Us </a>
             <button className="nav-btn nav-close-btn" onClick={showNavbar} > 
               <FaTimes />
             </button>

          </nav>
           <button className="nav-btn" onClick={showNavbar} >
             <FaBars />
           </button>
         </div>
       );
   }

 export default Navbar;
